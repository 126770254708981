<template>
  <el-aside :width="isCollapse ? '65px' : '200px'">
    <div class="side-menu" :width="isCollapse ? '65px' : '200px'">
      <div class="tog-but" @click="togCollapse">
        <i :class="['icon', !isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold']" ></i>
      </div>
<!--
      <div class="personal" @click="personCenter"><i class="el-icon-user icon-t2"></i>个人主页</div>
      <div class="buynow-btn" @click="orderNow" v-show="!isCollapse" style="background-color:#ff3366;font-weight:600;"><i class="el-icon-shopping-cart-2 icon-t"></i>礼品下单 </div>
      <div class="buynow-btn" @click="orderNow" v-show="!isCollapse" style="background-color:#339900;"><i class="el-icon-shopping-cart-2 icon-t"></i>无货源下单 </div>
      <div class="buynow-btn" @click="orderNow" v-show="!isCollapse"><i class="el-icon-shopping-cart-2 icon-t"></i>快速下单 </div>
-->
      <div class="el-submenu__title" @click="personCenter">
        <i data-v-0f3b045a="" class="el-icon-user icon-t" style="color:#f30;"></i>
        <span data-v-0f3b045a="" style="color:#f30;font-weight:600;">个人主页</span>
      </div>
 <!--
      <div class="el-submenu__title" @click="testPage">
        <i data-v-0f3b045a="" class="el-icon-user icon-t" style="color:#f30;"></i>
        <span data-v-0f3b045a="" style="color:#f30;font-weight:600;">测试页面</span>
      </div>
-->
      <div class="el-submenu__title" @click="orderNow">
        <i data-v-0f3b045a="" class="el-icon-shopping-cart-full icon-t" style="color:#0f80fd;"></i>
        <span data-v-0f3b045a="" style="color:#0f80fd;font-weight:600;">礼品下单</span>
      </div>
      <div class="el-submenu__title" @click="orderFast">
        <i data-v-0f3b045a="" class="el-icon-shopping-cart-2 icon-t" style="color:#0198e9;"></i>
        <span data-v-0f3b045a="" style="color:#0198e9;font-weight:600;">快速下单</span>
      </div>
 <!--
      <div class="el-submenu__title" @click="orderNosource">
        <i data-v-0f3b045a="" class="el-icon-shopping-cart-1 icon-t" style="color:#0198e9;"></i>
        <span data-v-0f3b045a="" style="color:#0198e9;font-weight:600;">无货源下单</span>
      </div>
-->
      <!-- <div class="buynow-btn" @click="personCenter" v-show="!isCollapse"> 个人主页 </div> -->
       <!-- width折叠和展开菜单时的宽度 -->
      <!-- style中的top，是，在折叠时，隐藏了下单按钮，并上移了菜单的top值 -->
      <el-menu :default-active="$route.path"
        :collapse="isCollapse"
        class="el-menu-vertical-demo"
        router @select="handleSelect"
        :width="isCollapse ? '65px' : '200px'"
        :style="{ top: isCollapse ? '120px' : '0' }"
        :collapse-transition="false"
      >
        <el-submenu :index="item.index" v-for="(item, index) in menu" :key="index"
          :style="{ width: isCollapse ? '65px' : '200px' }" >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="(i, k) in item.items"
              :key="k"
              :index="i.path"
              >{{ i.name }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </el-aside>
</template>

<script>
export default {
  data() {
    return {
      menu: [
         //{
         //  index: '/account/mycenter',
         //  title: '个人主页',
         //  icon: 'el-icon-user'
         //},
        {
          index: '/orderAdmin',
          title: '订单管理',
          icon: 'el-icon-set-up',
          items: [
	  /*
            {
              name: '无货源下单',
              path: '/orderAdmin/purchaseNosource'
            },
            {
              name: '快速下单',
              path: '/orderAdmin/purchaseFast'
            },
            {
              name: '礼品下单',
              path: '/orderAdmin/purchase'
            },
	  */
            {
              name: '订单记录',
              path: '/orderAdmin/orderList'
            },
            {
              name: '包裹记录',
              path: '/orderAdmin/parcelList'
            },
            {
              name: '店铺绑定',
              path: '/orderAdmin/storeBind'
            }
	    // ,
            // {
            //   name: '发件地址',
            //   path: '/orderAdmin/sendAddress'
            // }
          ]
        },
        {
          index: '/finance',
          title: '资金管理',
          icon: 'el-icon-money',
          items: [
            {
              name: '充值',
              path: '/account/recharge'
            },
            {
              name: '资金明细',
              path: '/account/capitalFlow'
            },
            {
              name: '奖金提现',
              path: '/account/cashout'
            }
          ]
        },
        {
          index: '/account',
          title: '个人中心',
          icon: 'el-icon-user',
          items: [
            {
              name: '基础信息',
              path: '/account/base'
            },
            /* {
              name: '资金管理',
              path: '/account/balance'
            },
            {
              name: '充值',
              path: '/account/recharge'
            },
            {
              name: '资金明细',
              path: '/account/capitalFlow'
            },
            {
              name: '奖金提现',
              path: '/account/cashout'
            },
            */
            {
              name: '邀请奖励',
              path: '/account/inviteRewards'
            },
            {
              name: '邀请好友',
              path: '/account/inviteFriends'
            },
            {
              name: '实名认证',
              path: '/account/realnameAuth'
            }
          ]
        }
	/*,
        {
          index: '/workOrder',
          title: '工单中心',
          icon: 'el-icon-document',
          items: [
            {
              name: '提交工单',
              path: '/workOrder/orderSumbit'
            },
            {
              name: '我的工单',
              path: '/workOrder/myOrder'
            }
          ]
        }*/
      ],
      isCollapse: false // 菜单展开切换
    }
  },
  methods: {
    testPage() {
      this.$router.push('/orderAdmin/testPage')
    },
    // 立即下单
    orderNow() {
      this.$router.push('/orderAdmin/purchase')
    },
    orderNosource() {
      this.$router.push('/orderAdmin/purchaseNosource')
    },
    orderFast() {
      this.$router.push('/orderAdmin/purchaseFast')
    },
    // 菜单激活回调
    handleSelect(indexPath) {
      this.$emit('getIndexPath', indexPath)
    },
    // 点击按钮切换展开
    togCollapse() {
      this.isCollapse = !this.isCollapse
    },
    personCenter() {
      this.isactive = 0
      this.$router.push('/account/mycenter')
    }
  }
}
</script>

<style scoped src="styles/components/sideMenu.css">
</style>

<style>
.side-menu .el-menu-vertical-demo.el-menu {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  /* overflow-y: auto; */
}

.side-menu .el-menu-vertical-demo.el-menu::-webkit-scrollbar {
  display: none;
}

.side-menu .el-menu {
  border-right: 0px;
}

.side-menu .el-menu .el-menu-item {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

.side-menu .el-submenu__title i:last-child {
  font-weight: 600;
}

.side-menu .el-submenu__title,
.side-menu .el-submenu__title i {
  color: #000000a6;
}

.side-menu .el-submenu.is-opened .el-submenu__title,
.side-menu .el-submenu.is-opened .el-submenu__title i {
  color: #1890ff;
}

.side-menu .el-menu .el-menu-item.is-active {
  color: #1890ff;
  border-right: 3px #1890ff solid;
}

.side-menu .icon-t{
  margin-right:10px;
  font-size:18px;
  color: #1890ff;
}
.side-menu .icon-t2{
  margin-right:10px;
  font-size:18px;
  color: #ff0000;
}

.tog-but {
  height: 30px;
  /* background-color: aqua; */
  right: right;
  /* background-color: rgb(238, 238, 238); */
  font-size: 16px;
  /* line-height: 15px; */
  color: rgb(22, 22, 24);
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.tog-but:hover {
  color: rgb(24, 144, 255);
  background-color: rgb(236, 245, 255);
  border-radius: 2px;
  transition: all 0.2s;
}
</style>
